<template>
  <div>
    <div>
      <div class="head">
        <div style="display: flex; justify-content: center">
          <div>
            <div class="headBanner">
              <div class="logoImg">
                <img style="width: 100%; height: 100%"
                     :src="this.$store.state.m_user.logo"
                     alt="" />
              </div>

              <div class="searChBox">
                <div style="width: 271px">
                  <el-input class="searCh"
                            placeholder="搜索感兴趣的课程资源"
                            v-model="className"
                            prefix-icon="el-icon-search"
                            ></el-input>
                </div>
                <!-- <input /> -->
                <!-- <img class="searchIcon"
                   src="../../assets/indexImg/search.png"
                   alt=""> -->
                <div class="searBtn"
                     @click="searClass">搜 索</div>
              </div>

              <div class="userxi"
                   v-if="this.$store.state.m_user.realName == '' || this.$store.state.m_user.realName == null">
                <div style="cursor: pointer"
                     class="mlet hov"
                     @click="login">登录</div>
                <div class="mlet">/</div>
                <div style="cursor: pointer"
                     class="mlet hov"
                     @click="regist">注册</div>
              </div>

              <div class="userxi"
                   v-else>
                <div style="cursor: pointer; color: #2878ff"
                     class="mlet hov"
                     @click="goStudy">{{ this.$store.state.m_user.realName }}</div>
                <div class="mlet">|</div>
                <div style="cursor: pointer"
                     class="mlet hov"
                     @click="loginOut">退出</div>
              </div>
            </div>
            <div class="navBox"
                 style="position: relative">
              <div class="tab1">
                <div style="display: flex"
                     @click="changeKe">
                  <img class="lu"
                       src="../../assets/indexImg/lu.png"
                       alt="" />
                  <div class="textLus">课 程 类 目</div>
                  <img v-if="iconSHow"
                       class="topIcon"
                       src="../../assets/indexImg/topIcon.png"
                       alt="" />
                  <img v-if="!iconSHow"
                       class="topIcon"
                       src="../../assets/indexImg/arrow_down.png"
                       alt="" />
                </div>

                <div class="tab1List"
                     v-if="iconSHow"
                     style="">
                  <div style="position: relative; z-index: 10000">

                    <div class="list1"
                         v-for="item in this.$store.state.m_user.list"
                         :key="item.id"
                         style="margin-top: 20px;">
                      {{ item.codeName }}

                      <div class="slist">
                        <div class="slistbox"
                             v-for="msg in item.codeList"
                             :key="msg.id"
                             @click="goList(msg, item)">
                          {{ msg.codeName }}
                        </div>
                        <div class="slistbox"
                             style="color: #1f73bc; text-decoration: underline"
                             @click="moke">更多</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div :class="path == '/' ? 'tab active' : 'tab'"
                   @click="navGet('1')">网站首页</div>
              <div :class="path == '/lsitFs' ? 'tab active' : 'tab'"
                   @click="navGet('2')">报名选课</div>
              <div :class="path == '/car' ? 'tab active' : 'tab'"
                   @click="navGet(3)">购物车</div>
              <div :class="path == '/studyMain'? 'tab active' : 'tab'"
                   @click="navGet(7)">学习中心</div>
              <div :class="path == '/wzgn' ? 'tab active' : 'tab'"
                   @click="navGet(4)">帮助中心</div>
              <div :class="path == '/tzgg' ? 'tab active' : 'tab'"
                   @click="navGet(5)">新闻资讯</div>
              <div :class="path == '/zscy' ? 'tab active' : 'tab'"
                   @click="navGet(6)">证书查验</div>
              <div :class="path == '/studyMain1'  ? 'tab active' : 'tab'"
                   @click="navGet(8)">申请发票</div>

              <div style="
                position: absolute;
                top: 0px;
                right: -200px;
                font-size: 16px;
                font-weight: bold;
                color: #ed0f08;
                width: 220px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background: rgba(237, 15, 8, 0.1);
                border-radius: 6px;
              ">
                客服联系: 400-8816-822
              </div>
            </div>
          </div>
        </div>
        <div class="navBorder"></div>
      </div>
    </div>

    <div style="display: flex; justify-content: center; background: #f7f7f7">
      <div class="center">
        <div class="videoHeader">
          <div class="video"
               style="position: relative">
            <img style="position: absolute; top: 10px; right: 30px; z-index: 99"
                 src="../../assets/indexImg/video_new1.png"
                 alt="" />
            <video id="videoPlayer"
                   style="width: 100%; height: 100%"
                   class="video-js videoBox"
                   muted
                   @contextmenu.prevent="handleContextMenu"></video>
          </div>
          <div class="table">
            <div class="flex jusbt"
                 style="padding: 10px; border-bottom: 1px solid #eee; padding-bottom: 10px">
              <div class="flex">
                <div :class="tabIndex == 1 ? 'active' : ''"
                     @click="tabNav(1)"
                     style="cursor: pointer">章节</div>
                <div :class="tabIndex == 2 ? 'active' : ''"
                     style="margin-left: 30px; cursor: pointer"
                     @click="tabNav(2)">简介</div>
              </div>

              <div class="cur"
                   @click="goKec"
                   style="color: #2878ff">返回课程资源库</div>
            </div>

            <div class="listBox">
              <div class="for"
                   v-show="tabIndex == 1">
                <div style="border-bottom: 1px solid #eee; padding: 10px 0"
                     v-for="(item, index) in chapterList"
                     :key="item.ID">
                  <div :class="playIndex == index ? 'active' : ''">
                    第{{ index + 1 }}节 <span style="color: #2878ff"
                          v-if="playIndex == index">正在播放</span>
                  </div>

                  <div :class="playIndex == index ? 'active flex text jusbt' : 'flex text jusbt'"
                       style="margin-top: 5px">
                    <div class="ovtxt">{{ item.CW_NAME }}</div>
                    <el-button style="width: 44px; height: 44px"
                               type="primary"
                               size="mini"
                               icon="el-icon-video-play"
                               circle
                               @click="play(item, index)"></el-button>
                  </div>
                </div>
              </div>

              <div class="for"
                   v-show="tabIndex == 2"
                   style="border: 0">
                <div>
                  {{ course.C_DESC == null ? '暂无简介~' : course.C_DESC }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex jusbt"
             style="margin-top: 20px">
          <div class="left">
            <div style="color: #2878ff; font-size: 18px">推荐课程</div>

            <div class="flex"
                 style="flex-wrap: wrap">
              <div class="forBox"
                   v-for="item in recommendCourseList"
                   :key="item.ID"
                   @click="toCourseDetail(item.ID)">
                <div>
                  <img v-if="item.C_IMAGE && item.C_IMAGE !== null && item.C_IMAGE !== ''"
                       class="boxImg"
                       style="width: 100%; height: 149px"
                       :src="item.C_IMAGE"
                       alt=""
                       @error="handleError" />
                  <div class="boxImg"
                       v-else
                       style="position: relative; display: flex; justify-content: center; align-items: center; width: 100%; height: 149px">
                    <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1"
                         src="../../assets/indexImg/noimg.png"
                         alt="" />
                    <div style="
                        position: relative;
                        z-index: 2;
                        font-size: 15px;
                        font-family: kaiti;
                        text-align: center;
                        padding: 0 10px;
                        font-weight: bold;
                        color: #355fd9;
                      ">
                      {{ item.C_NAME }}
                    </div>
                  </div>
                </div>
                <div class="textova"
                     style="text-align: center">
                  {{ item.C_NAME }}
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div style="color: #2878ff; font-size: 18px">猜你喜欢</div>

            <div class="flex cur"
                 style="margin-top: 20px; height: 108px"
                 v-for="item in guessLikeCourseList"
                 :key="item.ID"
                 @click="toCourseDetail(item.ID)">
              <div>
                <img v-if="item.C_IMAGE && item.C_IMAGE !== null && item.C_IMAGE !== ''"
                     class="boxImg"
                     style="width: 130px; height: 108px"
                     :src="item.C_IMAGE"
                     alt=""
                     @error="handleError" />
                <div class="boxImg"
                     v-else
                     style="position: relative; display: flex; justify-content: center; align-items: center; width: 130px; height: 108px">
                  <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1"
                       src="../../assets/indexImg/noimg.png"
                       alt="" />
                  <div style="
                      position: relative;
                      z-index: 2;
                      font-size: 15px;
                      font-family: kaiti;
                      text-align: center;
                      padding: 0 10px;
                      font-weight: bold;
                      color: #355fd9;
                    ">
                    {{ item.C_NAME }}
                  </div>
                </div>
              </div>

              <div style="margin-left: 15px">
                <div class="rigText">{{ item.C_NAME }}</div>
                <div class="jj">简介：{{ item.C_DESC }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title=""
               :visible.sync="dialogVisible"
               width="530px">
      <div style="display: flex; justify-content: center">
        <div>
          <div style="font-size: 16px; color: #333333; font-weight: bold">章节试看已结束，你可以通过选择以下方式继续观看课程</div>
          <div style="display: flex; margin-top: 28px">
            <div style="width: 170px"
                 v-if="courseInfo.course">
              <div style="text-align: center">方式一</div>
              <div style="width: 170px; height: 164px; padding: 15px 15px 0 15px; background: #f4f5f7; margin-top: 12px">
                <img style="width: 140px !important;height: 112px !important;"
                     v-if="courseInfo.course.C_IMAGE && courseInfo.course.C_IMAGE !== null && courseInfo.course.C_IMAGE !== ''"
                     :src="courseInfo.course.C_IMAGE"
                     alt=""
                     @error="handleError" />

                <div v-else
                     style="
                      position: relative;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      width: 140px !important;height: 112px !important;
                    ">
                  <img style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 1; "
                       src="../../assets/indexImg/noimg.png"
                       alt="" />
                  <div style="
                        position: relative;
                        z-index: 2;
                        font-size: 15px;
                        font-family: kaiti;
                        text-align: center;
                        padding: 0 10px;
                        font-weight: bold;
                        color: #355fd9;
                      ">
                    {{ courseInfo.course.C_NAME }}
                  </div>

                </div>
                <div style="margin-top: 8px; color: #333333 !important; font-size: 12px;width: 140px;overflow: hidden;
 white-space: nowrap;
text-overflow: ellipsis;"
                     :title="courseInfo.course.C_NAME">
                  {{ courseInfo.course.C_NAME }}
                </div>
              </div>

              <div style="
                  width: 120px;
                  height: 34px;
                  background: #2878ff;
                  text-align: center;
                  line-height: 34px;
                  font-size: 12px;
                  color: #fff;
                  border-radius: 17px 17px 17px 17px;
                  cursor: pointer;
                  margin: 12px auto;
                "
                   @click="payCourse">
                ¥{{ courseInfo.course.C_PRICE }} 购买此课程
              </div>
            </div>

            <div style="width: 170px; margin-left: 50px">
              <div style="text-align: center">方式二</div>
              <div style="width: 170px; height: 164px; padding: 15px 15px 0 15px; background: #f4f5f7; margin-top: 12px">
                <img style="width: 140px !important;height: 112px !important;"
                     src="../../assets/images/vip.png"
                     alt="" />
                <div style="margin-top: 8px; color: #333333 !important; font-size: 12px">开通会员无限量观看</div>
              </div>

              <div style="
                  width: 120px;
                  height: 34px;
                  background: linear-gradient(90deg, #fff2d9 0%, #f9dea2 100%);
                  text-align: center;
                  line-height: 34px;
                  font-size: 12px;
                  color: #957b5f;
                  border-radius: 17px 17px 17px 17px;
                  cursor: pointer;
                  margin: 12px auto;
                "
                   @click="ktVip">
                ¥{{ courseInfo.memberPrice }} 开通年会员
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div>
      <div class="bottom">
        <div class="bottomContent">
          <div>
            <div class="neir">
              <div @click="goDetil(item)"
                   class="fo"
                   style="display: flex;"
                   v-for="item in this.$store.state.m_user.aboutList"
                   :key="item.ID">
                <div>
                  {{ item.TITLE }}
                </div>
                <span style="margin: 0 8px;">|</span>
              </div>

            </div>

            <!-- <div class="nd">
            联系方式:客服电话400-8816-822（ 周一至周五8:30-12:00/14:00-17:30）
          </div>
          <div class="nd">
            客服QQ: 3550339480
          </div>
          <div class="nd">
            客服QQ群: 458367595
          </div>
          <div class="nd">
            客服邮箱: zhangjuan@gc80.cn
          </div> -->
            <div v-if="this.$store.state.m_user.bottomValue">
              <div v-html="this.$store.state.m_user.bottomValue.PIC_DESC">

              </div>
            </div>
          </div>

          <div>
            <div class="tu"
                 v-if="this.$store.state.m_user.gzhEwm">
              <img :src="this.$store.state.m_user.gzhEwm.PIC_URL"
                   :alt="this.$store.state.m_user.gzhEwm.PIC_TITLE">
              <div style="text-align: center;">
                {{ this.$store.state.m_user.gzhEwm.PIC_TITLE }}
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="tio"
           v-if="this.$store.state.m_user.beiAn">
        <a href="https://beian.miit.gov.cn/"
           target="_blank"
           v-html="this.$store.state.m_user.beiAn.PIC_DESC">

        </a>
      </div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import pcHeaders from '../component/head.vue';
import pcBottom from '../component/bottom.vue';
import { toCourseDetail, toTrialCourceInfo } from '@/api/lsitFs.js';
import { toGenerateOrder } from '@/api/pay.js';
import { Loading } from 'element-ui';
import { logout } from '@/api/login.js';
import { mapMutations } from 'vuex';
import Videojs from 'video.js';
import 'videojs-contrib-hls';
import '@videojs/http-streaming';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {
    pcHeaders,
    pcBottom
  },
  data() {
    // 这里存放数据
    return {
      dialogVisible: false, //引导购买
      tabIndex: 1,
      chapterList: [], //视频列表数据
      course: {}, //简介数据
      recommendCourseList: [], //推荐课程
      guessLikeCourseList: [], //推荐课程
      options: {
        autoplay: true, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: 'auto', // 预加载
        controls: true // 显示播放的控件
      },
      player: null,
      playIndex: 0,
      courseId: '',
      defaultImageUrl: require('../../assets/images/noimg.png'),
      is: {},
      courseInfo: {},
      payFalg: true, //购买防止重复点击
      iconSHow: false,
      indexShow: 1,
      logo: '',
      url: '',
      list: [],
      path: '',
      className: '',
      userName: '', //用户名称
      localValue: '0702',
      fp:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: { ...mapMutations('m_user', ['REAL_NAME']),
    //点击姓名跳到学习中心
    goStudy() {
      this.$router.push({
        path: '/studyMain'
      });
    },
    //更多
    moke() {
      this.$router.push({
        path: '/lsitFs'
      });
    },
    //目录点击
    goList(msg, item) {
      console.log(msg, item);
      this.$router.push({
        path: '/lsitFs',
        query: {
          codeNo: msg.codeNo, //第二级
          fCodeNo: item.codeNo //第一级
        }
      });
    },
    //退出登录
    loginOut() {
      this.REAL_NAME('');
      localStorage.removeItem('token');
      this.userName = '';
      logout({}).then(res => {
        this.$message({
          message: '退出登录成功！',
          type: 'success'
        });
        this.$router.push('/');
      });
    },
    searClass() {
      this.$router.push({
        path:'/resourcesList',
        query:{
          name:this.className
        }
      })
    },
    changeKe() {
      this.iconSHow = !this.iconSHow;
      console.log(this.iconSHow);
    },
    login() {
      this.$router.push('/login');
    },
    regist() {
      this.$router.push('/regist');
    },
    navGet(id) {
      this.indexShow = id;
      console.log(this.indexShow);

      if (id == 1) {
        this.$router.push('/').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 2) {
        this.$router.push('/lsitFs').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 3) {
        this.$router.push('/car').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 4) {
        this.$router.push('/wzgn').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 5) {
        this.$router.push('/tzgg').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 6) {
        this.$router.push('/zscy').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 7) {
        this.$router.push('/studyMain').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 8) {
        this.$router
          .push({
            path: '/studyMain1',
            query: {
              id: '8'
            }
          })
          .catch(err => {
            console.log('重复路由');
          });
      }
    },
    destroyed() {
      localStorage.removeItem('userName1');
    },
    goDetil(item) {
      console.log(item);
      this.$router.push({
        path: '/bottomDetil',
        query: {
          name: item.NAME,
        }
      });
    },
    payCourse() {
      let data = {
        courseId: this.courseInfo.course.ID
      };
      if (!this.payFalg) {
        return false;
      }
      this.payFalg = false;
      toGenerateOrder(data).then(res => {
        console.log(res);
        if (res.data.data.code == '1') {
          this.payFalg = true;
          this.$router.push({
            path: '/toPayOrder',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '4') {
          this.payFalg = true;
          this.$router.push({
            path: '/paySuccess',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '3') {
          this.payFalg = true;
          this.$message(res.data.data.msg);
          return; 
        }
      });
    },
    ktVip() {
      // 对响应数据做点什么
      let loadingInstance = Loading.service({
        lock: true,
        text: '创建订单中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, .2)'
      });
      let data = {
        isMember: '02'
      };
      toGenerateOrder(data).then(res => {
        console.log(res);
        loadingInstance.close();
        if (res.data.data.code == '1') {
          this.$router.push({
            path: '/toPayOrder',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '4') {
          this.$router.push({
            path: '/paySuccess',
            query: {
              orderId: res.data.data.orderId
            }
          });
        } else if (res.data.data.code == '3') {
          this.$message(res.data.data.msg);
          
          return;
        }
      });
    },
    handleContextMenu() {
      this.$message('右击被禁用！');
    },
    handleError(event) {
      event.target.src = this.defaultImageUrl;
    },
    play(item, index) {
      console.log(item, index);
      let leng = this.chapterList.length;
      if (this.is.isMember !== '02') {
        if (leng <= 5 && index > 0) {
          toTrialCourceInfo({ courseId: this.$route.query.courseId }).then(res => {
            console.log(res.data.data);
            this.courseInfo = res.data.data;
            this.dialogVisible = true;
          });
          // this.$message('只可试听第一个课件！');
          return false;
        } else if (leng <= 10 && index > 1) {
          toTrialCourceInfo({ courseId: this.$route.query.courseId }).then(res => {
            console.log(res.data.data);
            this.courseInfo = res.data.data;
            this.dialogVisible = true;
          });
          // this.$message('只可试听前两个课件！');
          return false;
        } else if (leng > 10 && index > 2) {
          toTrialCourceInfo({ courseId: this.$route.query.courseId }).then(res => {
            console.log(res.data.data);
            this.courseInfo = res.data.data;
            this.dialogVisible = true;
          });
          // this.$message('只可试听前三个课件！');
          return false;
        }
      }

      this.getVideo(item);
      this.playIndex = index;
    },
    tabNav(id) {
      this.tabIndex = id;
    },
    //返回资源库
    goKec() {
      this.$router.push('/resourcesList');
    },
    getVideo(videoUrl) {
      this.player = Videojs('videoPlayer', this.options);
      if (this.is.isZjw == '1' || this.is.isYlx == '1') {
        let url = videoUrl.LAUNCH;
        this.player.src(url);
      } else {
        if (/\.m3u8$/.test(videoUrl.LAUNCH)) {
          //判断视频源是否是m3u8的格式
          this.player.src([
            {
              src: videoUrl.LAUNCH,
              type: 'application/x-mpegURL' // 告诉videojs,这是一个hls流
            }
          ]);
        } else {
          let url = videoUrl.LAUNCH + '.mp4';
          this.player.src(url);
        }
      }

      let _this = this;
      _this.player.on('timeupdate', function () {
        // console.log('播放时长改变');
        // if (_this.player.currentTime() >= 300) {
        //   _this.player.currentTime(0);
        //   _this.player.pause();
        //   _this.$message('视频试听五分钟结束，请购买后继续学习！');
        // }
        // console.log(_this.player.currentTime());
      });
    },
    toCourseDetail(courseId) {
      //获取详情
      toCourseDetail({ courseId: courseId, token: this.$store.state.m_user.token }).then(res => {
        this.is = res.data.data;
        console.log(this.is);
        this.chapterList = res.data.data.chapterList;
        this.course = res.data.data.course;
        this.recommendCourseList = res.data.data.recommendCourseList;
        this.guessLikeCourseList = res.data.data.guessLikeCourseList;
        this.getVideo(this.chapterList[0]);
        this.playIndex = 0;
      });
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.toCourseDetail(this.$route.query.courseId);
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeDestroy() {
    if (this.player) {
      this.player.dispose(); //重置videojs的内部状态并移除dom
    }
  }
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.head {
  font-family: Regular;
  width: 100%;
  background: #fff;
  padding-top: 20px;

  .headBanner {
    width: 1200px;
    display: flex;
    height: 80px;
    justify-content: space-between;

    .logoImg {
      width: 220px;
      height: 60px;
    }
    .searChBox {
      width: 350px;
      height: 40px;
      margin-top: 12px;
      position: relative;
      .el-input__inner {
        width: 350px !important;
        height: 40px !important;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 20px !important;
        padding-left: 46px !important;
        padding-right: 80px !important;
        position: relative !important;
        color: #dddddd !important;
      }
      .searCh {
      }
      .searchIcon {
        position: absolute;
        top: 10px;
        left: 20px;
        width: 16px;
        height: 16px;
      }
      .searBtn {
        position: absolute;
        right: 0;
        top: 0;
        width: 80px;
        height: 40px;
        background: #2878ff;
        border: 1px solid #2878ff;
        opacity: 1;
        font-size: 16px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        border-radius: 0px 20px 20px 0px;
      }
    }
    .userxi {
      margin-top: 25px;
      font-size: 14px;
      color: #333;
      display: flex;
      height: 20px;
      .mlet {
        margin-left: 5px;
      }
      .hov:hover {
        opacity: 0.7;
      }
    }
  }
  .navBox {
    width: 1200px;
    height: 48px;
    display: flex;
    .tab1 {
      width: 220px;
      height: 48px;
      background: #2878ff;
      opacity: 1;
      border-radius: 0px;
      padding-left: 10px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      .tab1List {
        position: absolute;
        bottom: -403px;
        width: 220px;
        height: 403px;
        background: #fff;
        left: 0;
        z-index: 9999;
        box-shadow: 0px 0px 4px #333333;
        font-size: 16px;
        color: #000;
        padding: 12px 10px;
        .list1 {
          margin-bottom: 30px;
          cursor: pointer;
          .slist {
            display: flex;
            margin-top: 10px;
            flex-wrap: wrap;
            .slistbox {
              cursor: pointer;
              font-size: 14px;
              color: #999;
              opacity: 0.7;
              margin-right: 10px;
              margin-bottom: 10px;
            }
            .slistbox:hover {
              opacity: 1;
            }
          }
        }
      }
      .lu {
        width: 20px;
        height: 16px;
        margin-top: 5px;
      }
      .textLus {
        margin-left: 10px;
      }
      .topIcon {
        width: 15px;
        height: 8px;
        margin-top: 9px;
        margin-left: 54px;
      }
    }

    .tab {
      width: 120px;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      cursor: pointer;
    }
    .active {
      color: #2878ff;
      font-weight: bold;
    }
  }
  .navBorder {
    width: 100%;
    border-bottom: 2px solid #2878ff;
  }
}
a {
  color: #fff;
  text-decoration: none;
}
.bottom {
  font-family: Regular;
  width: 100%;
  height: 249px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background: #474747;
  .bottomContent {
    width: 1200px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .neir {
      color: #fff;
      display: flex;
      cursor: pointer;
      .fo:last-child {
        span {
          display: none;
        }
      }
    }
    .nd {
      margin: 15px 0;
    }
    .tu {
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
.tio {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 14px;
  .footer-section2 {
    margin: 0;
  }
}

.flex {
  display: flex;
}
.jusbt {
  justify-content: space-between;
}
.cur {
  cursor: pointer;
}
.active {
  color: #2878ff;
}
.center {
  width: 1200px;
  padding: 30px 0;
  font-size: 16px;
  background: #f7f7f7;
  .videoHeader {
    display: flex;
    height: 475px;
    .video {
      width: 844px;
      background: #ccc;
      height: 475px;
    }
    .table {
      width: 356px;
      height: 475px;
      background: #fff;
      .listBox {
        padding: 0 15px 15px 15px;
        overflow-y: scroll;
        height: 432px;
        .for {
          padding-bottom: 10px;
          .text {
            width: 316px;

            .ovtxt {
              width: 260px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

  .left {
    width: 840px;
    padding: 15px;
    background: #fff;
    .forBox {
      width: 180px;
      margin-right: 30px;
      margin-top: 20px;
      cursor: pointer;

      .textova {
        width: 180px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .forBox:nth-child(4n) {
      margin-right: 0;
    }
  }

  .right {
    background: #fff;
    width: 346px;
    margin-left: 10px;
    padding: 15px;
    .rigText {
      width: 156px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: #333;
    }
    .jj {
      margin-top: 10px;
      font-size: 14px;
      color: #999;
      width: 156px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
